import NavbarSlider from './navbar/NavbarSlider'
import NeuroLogo    from './logo/NeuroLogo'

export default class Navbar extends React.PureComponent
  constructor: (props) ->
    super(props)

    @state =
      mobileNavbarIsOpen: false

    # Use a reference for Bootstrap's collapsible navbar (mobile view)
    @collapseRef = React.createRef()

    # Bound method
    @changeMode = @changeMode.bind(this)

  componentDidMount: ->
    # We watch the opening and closing of Bootstrap's mobile navbar,
    # so we can synchronize our state with it
    @collapseRef.current.addEventListener('show.bs.collapse', (event) =>
      @setState(mobileNavbarIsOpen: true)
    )

    @collapseRef.current.addEventListener('hide.bs.collapse', (event) =>
      @setState(mobileNavbarIsOpen: false)
    )

  changeMode: (mode) ->
    # Close the mobile menu (if open) when changing mode
    if @state.mobileNavbarIsOpen
      Bootstrap.Collapse.getInstance(@collapseRef.current).hide()

    if @props.changeMode
      @props.changeMode(mode) # on main Neurooo page
    else
      window.location.href = "/#{@props.currentLocale}/#{mode}"; # on other pages

  render: ->
    <div className="navbar navbar-expand-md navbar-neurooo" role="navigation">
      <div className="container-lg navbar-container">
        <a href={"/#{@props.currentLocale}"} className="navbar-brand" title="Neurooo">
          <NeuroLogo/>
        </a>
        { @renderToggler() }
        { @renderMainNavbar() }
      </div>
    </div>

  renderToggler: ->
    <button className="navbar-toggler collapsed"
            ref={@togglerRef}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#main-navbar"
            aria-controls="main-navbar"
            aria-expanded="false"
            aria-label={@props.i18n.togglerLabel} >
      <i className="fas fa-bars"></i>
      <i className="fas fa-xmark"></i>
    </button>

  renderMainNavbar: ->
    <div className="navbar-collapse collapse" id="main-navbar" ref={@collapseRef}>
      { @renderSlider() }
      { @renderActions() }
      { @renderUserActions() }
    </div>

  renderSlider: ->
    modes = ['text', 'files', 'apps']

    <NavbarSlider modes={modes}
                  activeMode={@props.mode}
                  changeMode={@changeMode}
                  mobileNavbarIsOpen={@state.mobileNavbarIsOpen}
                  currentLocale={@props.currentLocale}
                  i18n={@props.i18n} />

  renderActions: ->
    if @props.openModal
      openModal = @props.openModal # on main Neurooo page
    else
      openModal = (action) => window.location.href = "/#{@props.currentLocale}/?modal=#{action}"; # on other pages

    <div className="actions navbar-nav">
      <a href="/#{@props.currentLocale}/?modal=api" className="link d-inline d-md-none d-lg-inline" type="button" onClick={openModal.bind(this, 'api')}>
        {@props.i18n.api}
      </a>
      &nbsp;
      <a href="/#{@props.currentLocale}/blog" className="link-blog link d-inline d-md-none d-lg-inline" type="button">
        { @props.i18n.blog   }
        { @renderBlogBadge() }
      </a>
    </div>

  renderBlogBadge: ->
    if @props.blogNotification
      <span className="badge rounded-pill">
        1 <span className="visually-hidden">{@props.i18n.unreadArticles}</span>
      </span>

  # Temporary buttons, for easy access to log-in and sign-up pages (+ sign-out button)
  # TODO: open modals when signed-out + display dropdown user menu when signed-in
  renderUserActions: ->
    if @props.currentUser
      <ul className="navbar-nav navbar-user">
        <li className="nav-item dropdown">
          <a href="#" className="nav-link" id="nav-link-user" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <img src={ @props.currentUser.avatarUrl } width="34" height="34" referrerPolicy="no-referrer"/>
            <div className="username-for-mobile">
              { @props.currentUser.name }
            </div>
          </a>
          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="nav-link-user">
            <li className="dropdown-item" >
              { @renderSignedInAs() }
            </li>
            <li><hr className="dropdown-divider"/></li>
            <li>
              <a className="dropdown-item" href={@props.paths.accountPath}>
                {@props.i18n.manageAccount}
              </a>
            </li>
            <li><hr className="dropdown-divider"/></li>
            <li>
              <a className="dropdown-item text-danger" href={@props.paths.signOutPath} data-method="delete">
                {@props.i18n.signOut}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    else
      <div className="navbar-nav navbar-visitor">
        <a href="#{@props.paths.signInPath}" className="btn sign-in">
          {@props.i18n.logIn}
        </a>
        <a href="#{@props.paths.signUpPath}" className="btn btn-primary sign-up">
          {@props.i18n.signUp}
        </a>
      </div>

  renderSignedInAs: ->
    innerHtml = @props.i18n.signedInAs.replaceAll('%{name}', @props.currentUser.name)

    <span dangerouslySetInnerHTML={{ __html: innerHtml }}></span>
